import axios from 'axios';

export default {
  search: function (request) {
    return axios.get('/api/statement/search', { params: request });
  },
  find: function (id) {
    return axios.get(`/api/statement/find/${id}`);
  },
  changeStatus: function (data) {
    return axios.put(`/api/statement/changestatus/${data.id}`, data);
  },
  getModerationStatuses: function () {
    return axios.get('/api/statement/moderationstatuses', {});
  },
};
