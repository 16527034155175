<template>
  <div class="container-page">
    <p class="title-inner">Заявления</p>
    <tabs v-if="regTitles" v-on:select="tabSelected">
      <tab v-for="(title, index) in regTitles" :name="title" :selected="index === 0" :key="index">
        <div>
          <div class="table-container mb-16 js-table-arrow">
            <table class="stockTable">
              <thead>
                <tr>
                  <th>№ заявления</th>
                  <th>Заявитель</th>
                  <th>Должность</th>
                  <th>Наименование компании</th>
                  <th>Отрасль экономики, в которой ведет деятельность компания</th>
                  <th>Телефон</th>
                  <th>Электронная почта</th>
                  <th>Сайт компании</th>
                  <th>Предмет обращения</th>
                  <template v-if="index === 0">
                    <th>Суть барьера</th>
                  </template>
                  <template v-else>
                    <th>Предложения по внесению изменений в договор</th>
                  </template>
                  <th>Дата подачи</th>
                  <th>Статус</th>
                  <th></th>
                </tr>
              </thead>
              <tbody v-if="registryBag[index].page?.items?.length && !registryBag[index].isLoading">
                <tr v-for="item in registryBag[index].page.items" :key="item.id" @dblclick="viewCard(item.id)">
                  <td>{{ item.id }}</td>
                  <td>{{ item.fio }}</td>
                  <td>{{ item.position }}</td>
                  <td>{{ item.companyName }}</td>
                  <td>{{ item.companyIndustry?.name }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.companyUrl }}</td>
                  <td>{{ getItemSubject(item) }}</td>
                  <td>
                    <p v-snip="3">{{ item.body }}</p>
                  </td>
                  <td>{{ $momentFormat(item.created, 'DD.MM.YYYY HH:mm') }}</td>
                  <td :class="item.status.cssClass">{{ item.status.title }}</td>
                  <td>
                    <IconComponent @click="viewCard(item.id)" name="mode" />
                  </td>
                </tr>
              </tbody>
              <tbody v-else-if="registryBag[index].isLoading">
                <tr>
                  <td class="text-center" colspan="12">Загрузка...</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td class="text-center" colspan="12">Нет данных.</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="registryBag[index].page.pagesTotal > 1">
            <Pagination
              :page-count="registryBag[index].page.pagesTotal"
              :current-page="registryBag[index].page.pageNumber"
              @change="onPageChange($event, false, index)"
              @more="onPageChange($event, true, index)"
            />
          </div>
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
  import tab from '@/common/components/tabsDetailInner.vue';
  import tabs from '@/common/components/tabsListInner.vue';
  import Constants from '@/common/constants';

  import IconComponent from '../../../common/ui/IconComponent';
  import UserApi from '../../admin/api/users';
  import Api from '../api/statement';

  export default {
    name: 'Statements',
    components: { IconComponent, tabs, tab },
    data() {
      return {
        registryBag: [],
        regTitles: ['Заявления о барьерах', 'Предложения по защитным мерам'],
        currentRegType: 0,
        employees: [],
        statuses: [],
        defaultRequest: {
          pageNumber: 1,
          pageSize: 0,
          isActive: true,
        },
      };
    },
    created() {
      this.loadDictionaries();
      this.registryBag = [];
      for (let index = 0; index < 2; index++) {
        this.registryBag.push({
          page: {},
          request: {
            pageNumber: 1,
            pageSize: Constants.pageSize,
            statementKind: index + 1,
          },
          filter: {},
          isLoading: false,
        });
        this.loadPage(index);
      }
    },
    methods: {
      loadPage(type, append) {
        this.registryBag[type].isLoading = true;
        const request = Object.assign(this.registryBag[type].filter, this.registryBag[type].request);
        Api.search(request)
          .then((response) => {
            if (!append) {
              this.registryBag[type].page = {};
              this.registryBag[type].page = response.data;
            } else if (response.data) {
              this.registryBag[type].page.pageNumber = response.data.pageNumber;
              this.registryBag[type].page.items = this.registryBag[type].page.items.concat(response.data.items);
            }
            this.registryBag[type].isLoading = false;
          })
          .catch((error) => {
            this.registryBag[type].isLoading = false;
            console.error(error?.response?.data);
          });
      },
      loadDictionaries() {
        UserApi.search(this.defaultRequest)
          .then((response) => {
            this.employees = response.data.items.map((x) => {
              return { text: x.fullName, id: x.userId };
            });
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
        Api.getModerationStatuses()
          .then((response) => {
            this.statuses = response.data.map((x) => ({ id: x.id, text: x.title }));
          })
          .catch((error) => {
            console.error(error?.response?.data);
          });
      },
      viewCard(id) {
        this.$router.push({ name: 'StatementCard', params: { id: id, action: 'view' } });
      },
      onPageChange(pageNumber, addToExist, type) {
        this.registryBag[type].request.pageNumber = pageNumber;
        this.loadPage(type, addToExist);
      },
      tabSelected(index) {
        this.currentRegType = index;
      },
      getItemSubject(item) {
        if (item.subject === 1) return 'Товары';
        if (item.subject === 2) return 'Услуги';
        return 'Инвестиции';
      },
    },
  };
</script>

<style lang="scss"></style>
